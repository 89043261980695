import { Mixins, Component } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import { VueClass } from "vue-class-component/lib/declarations";
import {
  PaymentMethod,
  PaymentMethodCollection,
} from "@planetadeleste/vue-mc-gw";
import type { Subjects } from "@/services/ability";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<PaymentMethod, PaymentMethodCollection>
>;

@Component
export default class PaymentmethodsMixin extends Mixins(TypedModelMixin) {
  obCollection = new PaymentMethodCollection();
  obModelClass = PaymentMethod;
  obCollectionClass = PaymentMethodCollection;
  sRoutePath = "/paymentmethods";

  created() {
    this.onCreated();
  }

  get modelClassName(): Subjects {
    return "PaymentMethod";
  }
}
